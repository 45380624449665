// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-theme-blog-core-src-templates-post-query-js": () => import("./../../../node_modules/gatsby-theme-blog-core/src/templates/post-query.js" /* webpackChunkName: "component---node-modules-gatsby-theme-blog-core-src-templates-post-query-js" */),
  "component---node-modules-gatsby-theme-blog-core-src-templates-posts-query-js": () => import("./../../../node_modules/gatsby-theme-blog-core/src/templates/posts-query.js" /* webpackChunkName: "component---node-modules-gatsby-theme-blog-core-src-templates-posts-query-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-boston-js": () => import("./../../../src/pages/boston.js" /* webpackChunkName: "component---src-pages-boston-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-philadelphia-js": () => import("./../../../src/pages/philadelphia.js" /* webpackChunkName: "component---src-pages-philadelphia-js" */),
  "component---src-pages-pittsburgh-js": () => import("./../../../src/pages/pittsburgh.js" /* webpackChunkName: "component---src-pages-pittsburgh-js" */),
  "component---src-templates-student-js": () => import("./../../../src/templates/student.js" /* webpackChunkName: "component---src-templates-student-js" */)
}

